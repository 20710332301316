import styles from "./Question.module.scss";

const Question = ({ number, text, handleYes, handleNo }) => {
  return (
    <>
      <p>
        {number}. {text}
      </p>
      <div className={styles.buttonRow}>
        <button onClick={handleYes}>Yes</button>
        <button onClick={handleNo}>No</button>
      </div>
    </>
  );
};

export default Question;
