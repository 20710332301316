import { useState, useEffect } from "react";
import Question from "./components/Question";
import logo from "./images/logo.png";
import styles from "./App.module.scss";

const STATUSES = {
  idle: "idle",
  running: "running",
  success: "success",
  failure: "failure",
};

const QUESTIONS = [
  "Last entry is strictly 6pm, will you be able to make it to the venue before the doors close? (Doors open at 4pm)",
  "Photographs and videos are not allowed at this event. Are you comfortable with your phone camera being covered with a sticker for the duration of the party?",
  "We are an open-minded and inclusive party - discrimination of any kind will not be tolerated at this event. Do you agree that partying should be a safe and fun place for everyone?",
];

const approvalImageSrc =
  "https://media0.giphy.com/media/IzQts6KDjZqTMkWAfe/giphy.gif?cid=ecf05e473s95nyfaxv6ut9f6aj3hkvt08baxeydgygdutvup&ep=v1_gifs_search&rid=giphy.gif&ct=g";

function App() {
  const [status, setStatus] = useState(STATUSES.idle);
  const [questionIndex, setQuestionIndex] = useState(0);

  // Preload images
  useEffect(() => {
    const img = new Image();
    img.src = approvalImageSrc;
  }, []);

  const handleReset = () => {
    setQuestionIndex(0);
    setStatus(STATUSES.idle);
  };

  const handleBegin = () => {
    setQuestionIndex(0);
    setStatus(STATUSES.running);
  };

  const handleYes = () => {
    const nextIndex = questionIndex + 1;

    if (nextIndex === QUESTIONS.length) {
      setStatus(STATUSES.success);
    } else {
      setQuestionIndex(nextIndex);
    }
  };

  const handleNo = () => {
    setStatus(STATUSES.failure);
    setQuestionIndex(0);
  };

  return (
    <div className={styles.App}>
      <a href="/">
        <img src={logo} className={styles.logo} alt="Snail Club" />
      </a>
      <main>
        {status === STATUSES.idle && (
          <>
            <header className={styles.header}>
              <h1>Secret Line-Up Courtyard Party II</h1>
            </header>

            <div className={styles.info}>
              <ul>
                <li>
                  We need to provide you with some key information about the
                  party before providing you with the ticket link...
                </li>
              </ul>
            </div>
            <button onClick={handleBegin}>Click here to begin</button>
          </>
        )}

        {status === STATUSES.running && (
          <Question
            text={QUESTIONS[questionIndex]}
            number={questionIndex + 1}
            handleYes={handleYes}
            handleNo={handleNo}
          />
        )}

        {status === STATUSES.success && (
          <>
            <p>YOU'RE IN</p>
            <img
              className={styles.approvalImage}
              src={approvalImageSrc}
              width={230}
              height={240}
              alt=""
            />
            <p>
              <a className="button" href="https://ra.co/events/1753317">
                Book your tickets at Resident Advisor
              </a>
            </p>
            <button className={styles.smallButton} onClick={handleReset}>
              Give me all the info again
            </button>
          </>
        )}

        {status === STATUSES.failure && (
          <>
            <p>Sorry, this party is not for you.</p>
            <button onClick={handleReset}>I messed up</button>
          </>
        )}
      </main>
    </div>
  );
}

export default App;
